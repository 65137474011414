<template>
  <div class="homePage">
    <div class="img-content">
      <img
        class="wx-xcx-ewm"
        src="./img/wx_applet_qrcode.jpg"
        @click="ewmOnClick"
      />
      <div class="label-text">小程序二维码</div>
    </div>
    <div class="content">
      <div
        class="section"
        v-for="(item, index) in list"
        :key="index"
        v-show="item.show"
        @click="redirect(item.url, index)"
      >
        <img :src="item.icon" alt="" />
        <p>{{ item.text }}</p>
      </div>
    </div>
    <!-- <div class="tip-text">如在一体机上完成注册，则无法在小程序上登录该账号；已在小程序注册用户可通过手机号的方式登录一体机</div> -->
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getAllFaceUserInfo, getYtjDeviceInfoUrl } from "./api.js";
import storage from "../utils/storage";

export default {
  name: "homePage",
  components: {},
  props: {},
  data() {
    return {
      list: [
        {
          icon: require("./img/register.png"),
          text: "我要注册",
          url: "register",
          show: false,
        },
        {
          icon: require("./img/login.png"),
          text: "我要登录",
          url: "register",
          show: true,
        },
      ],
    };
  },
  created() {
    if (window.sys && window.sys.getDeviceNo) {
      const deviceNo = window.sys.getDeviceNo();
      storage.set("deviceNo", deviceNo);
      this.$store.commit("deviceNo", deviceNo);
      this.$axios
        .get(getYtjDeviceInfoUrl, { params: { deviceNo } })
        .then((res) => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            this.list[0].show = res.data.canRegister === 1;
          }
        });
    }
  },
  mounted() {},
  methods: {
    //获取所有的脸用户数据
    getAllFaceUserInfo() {
      this.$axios.get(getAllFaceUserInfo).then((res) => {
        if (res.code == 200) {
          const { data } = res;
          this.loadFaceUserList(JSON.stringify(data));
        }
      });
    },
    //人脸入库
    loadFaceUserList(data) {
      window.sys.loadFaceUserList(data);
    },
    //跳转页面
    redirect(name, index) {
      this.getAllFaceUserInfo();
      this.$router.push({
        name,
        query: {
          isRegister: index,
        },
      });
    },
    ewmOnClick() {
      ImagePreview([require("./img/wx_applet_qrcode4.jpg")]);
    },
  },
};
</script>

<style scoped lang="less">
// .homePage {}
.img-content {
  box-sizing: border-box;
  padding: 30px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .wx-xcx-ewm {
    box-sizing: border-box;
    width: 40px;
    height: 40px;
  }
  .label-text {
    box-sizing: border-box;
    font-size: 24px;
    margin-left: 10px;
  }
}
.content {
  width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0 40px;
  .section {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 509px;
    height: 426px;
    background: linear-gradient(#7542ff 0%, #b98cff 100%);
    border-radius: 36px;
    box-sizing: border-box;
    &:last-child {
      margin-left: 55px;
      background: linear-gradient(#1c77ff 0%, #58bbff 100%);
    }
    img {
      width: 140px;
      height: 140px;
      margin-bottom: 57px;
      &:last-child {
        margin-bottom: 53px;
      }
    }
    p {
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: bold;
      font-size: 30px;
      color: #ffffff;
      line-height: 40px;
      text-align: left;
      font-style: normal;
    }
  }
}
.tip-text {
  box-sizing: border-box;
  padding: 20px 40px;
  font-size: 24px;
  text-align: center;
  color: red;
}
</style>
